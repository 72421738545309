<template>
    <div>
        <Breadcrumb title="Contact Us"  />
        <div class="about-area ptb-95">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <h4>Contact Us</h4>
                        <p class="mb-5">Have a question, comment, compliment or just want to leave feedback? We would love to hear from you.</p>
                        <!-- <h6 class="mt-5 mb-3">CONTACT FORM</h6> -->
                    </div>
                    <div class="col-md-12 mt-5"></div>
                    <div class="col-md-6">
                    
                        <div class="">
                            <input   type="text" v-model="messageData.name"  placeholder="Name*">
                            <input class="mt-3" v-model="messageData.email" type="email" placeholder="E-mail Address*">
                            <input class="mt-3" v-model="messageData.phone" type="text"  placeholder="Phone Number*">
                            
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="reserve-form-top">
                            
                            <textarea class="m-screen-mt" v-model="messageData.message" name="" id="" cols="30" rows="7" placeholder="Message"></textarea>
                            <div class="row mt-2">
                                <div class="col-md-9"><h1 class="float-right"><span>{{ num1 }} + {{ num2 }}</span> = </h1></div>
                                <div class="col-md-3 col-sm-2"><input v-model="result" class="m-style float-right mt-1" type="text"></div>
                            </div>
                            <button v-if="!loading" @click="submitMessage()" style="background:#e54c2a; border-radius: 8px; color: #fff; font-weight:bold; cursor: pointer" class="btn  float-right">Submit</button>
                            <button v-if="loading" style="background:#e54c2a; border-radius: 8px; color: #fff; font-weight:bold; cursor: pointer;width: 79px" class="btn  float-right"><i class="fa fa-spinner fa-spin"></i></button>
                        </div>
                    </div>
                    <!-- <div class="mt-150 text-center col-md-12">
                        <p>House 1, Road 54/A, Gulshan 2, Dhaka</p>
                        <p class="mt-neg-10">Tel: (02) 8837753, 8837754 | Mob: 01911080825, 01404009000 | E-mail: germanbutcherbd@gmail.com</p>
                        <p class="mt-neg-10">Join un on Social Media: 
                            <a href class="facebook icon-style"><i class="ion-social-facebook"></i></a>
                            <a href class="instagram icon-style"><i class="ion-social-instagram-outline"></i></a>
                            <a href class="youtube icon-style"><i class="ion-social-youtube"></i></a>
                        </p>
                    </div> -->
                </div>
            </div>
        </div>

        <!-- <div class="services-area pt-100 pb-70" style="background-image:url(./img/banner/count.jpg);">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div class="single-service text-center mb-30">
                            <i class="fa fa-map-marker"></i>
                            <h3>Our Location</h3>
                            <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit,</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div class="single-service text-center mb-30">
                            <i class="fa fa-mobile"></i>
                            <h3>Contact Us Now</h3>
                            <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit,</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div class="single-service text-center mb-30">
                            <i class="fa fa-envelope"></i>
                            <h3>Write Us Now</h3>
                            <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit,</p>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <notifications group="msg" position="top middle" />
        <!-- snackbar -->
            <v-snackbar v-model="snackbar">
                {{ text }}
                <v-btn color="pink" text  @click="snackbar = false">
                    Close
                </v-btn>
            </v-snackbar>
        <!-- snackbar -->
    </div>
</template>

<script>
    import Breadcrumb from '@/components/common/BreadcrumbComponent'
    //import GoogleMap from '@/components/common/GoogleMapComponent'
    //import {Breadcrumb, GoogleMap} from "@/common"
    export default {
        name: "contact",
        components: {Breadcrumb},
        data() {
            return {
                num1: Math.floor(Math.random() * 10),
                num2: Math.floor(Math.random() * 10),
                result: '',
                snackbar: false,
                text: '',
                loading: false,
                messageData: {},
            }
        },//data ends here
        methods: {
            
            async submitMessage(){
                if(this.num1 + this.num2 == this.result){
                    this.loading = true
                    let message = await this.$store.dispatch('contact/ACT_SEND_MESSAGE', this.messageData)
                    if(message.success == true){
                        this.loading = false
                        this.result = ''
                        this.messageData = {}
                        this.$notify({
                            group: 'msg',
                            type: 'success',
                            title: 'Success',
                            text: message.message,
                        });
                    }else{
                        this.loading = false
                        this.snackbar = true
                        this.text =message.message
                    }
                }else{
                    this.snackbar = true
                    this.text ="Wrong answer."
                }
                
            
        },
        },//methods end here
    }
</script>

<style scoped>
    .mt-26{
        margin-top: 26px;
    }
    .mt-150{
        margin-top: 150px;
    }
    .mt-neg-5{
        margin-top: -10px;
    }
    a, h1,h2,h3,h4,h5,h6,p{
        font-family: 'Ubuntu', sans-serif;
    }
    .facebook{
        margin-left: 30px;
        background-color: #3c5b9b;
        border: 2px solid #3c5b9b;
        
    }
    .instagram{
        background-color: #c619b8;
        border: 2px solid #c619b8;
    }
    .youtube{
        background-color: #ed3c32;
        border: 2px solid #ed3c32;
    }
    .icon-style{
        margin-right: 10px;
        font-size: 15px;
        height: 30px;
        line-height: 26px;
        width: 30px;
        border-radius: 50px;
        color: #fff;
        display: inline-block;
    }
    input,textarea{
        border:1px solid #e2e0e0;
    }
    input:focus, textarea:focus{
        border:1px solid #f33a3a;
    }
    @media only screen and (max-width: 768px){
        .m-style {
            margin-bottom: 20px !important;
            width: 100px;
        }
    }
    @media only screen and (max-width: 768px){
        .m-screen-mt {
            margin-top: 17px !important;
        }
    }
</style>