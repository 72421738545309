<template>
    <div class="breadcrumb-area bg-img " style="background-image:url(/img/banner/wood-bg-dark.jpg);">
        <div class="container">
            <div class="breadcrumb-content text-center">
                <!-- <h3>{{ title  }}</h3> -->
                <!-- <ul>
                    <li>
                        <router-link :to="{name: 'home'}">Home</router-link>
                    </li>
                    <li class="active">{{ title }}</li>
                </ul> -->
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props:['title'],
        name: "breadcrumb",
        data() {
            return {}
        },
        methods: {},
        mounted() {
        }
    }
</script>

<style scoped>
.breadcrumb-area{
    padding: 60px 0; 
}
@media only screen and (max-width: 993px){
      .breadcrumb-area {
          height: 40px;
          padding: 35px 0 !important;  
      }
  }
</style>